<script>
import DefaultSwagbagFileForm from "@/views/menu/event/defaultSwagbagFile/defaultSwagbagFileForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    defaultSwagbagFileService: "defaultSwagbagFileService",
    eventService: "eventService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
  }
})
export default class DefaultSwagbagFileNew extends mixins(DefaultSwagbagFileForm) {
  formId = "new-defaultSwagbagFile-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      let defaultSwagbagFile = {
        idEvent: this.$route.params.idEvent,
        idLanguage: this.model.idLanguage,
        idCategory: this.model.idCategory,
        name: this.model.name,
        description: this.model.description,
        uri: this.model.uri,
        assignmentPolicy: this.model.assignmentPolicy,
        users: this.model.users,
        visitorLevels: this.model.visitorLevels
      };
        await this.defaultSwagbagFileService.create(defaultSwagbagFile);
      this.$router.push(`/swagbag/list/${this.$route.params.idEvent}`);
      this.toastService.success(this.translations.success.defaultSwagbagFile_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.defaultSwagbagFile_new;
  }

  async created() {
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.$route.params.idEvent));
    this.model = {
        idLanguage: null,
        idCategory: null,
        name: "",
        description: null,
        uri: null,
        assignmentPolicy: "AllUsers",
        users: [],
        visitorLevels: this.defaultVisitorLevelsValue(),
    };
    this.isReady = true;
  }
}
</script>
